export enum SETTINGS_TABS {
  GENERAL = 'settings-general',
  PAYMENT = 'settings-payment',
  PROFILE = 'settings-profile',
  LOCATION = 'settings-location',
  USER_RIGHTS = 'settings-users',
  INTEGRATIONS = 'settings-integrations',
  EMAILS = 'emails',
  CHECKIN = 'check-in',
  CONTRACTS = 'contracts',
  PARTNERS = 'partners',
}

export enum CONTRACT_PROPS {
  DATE = '[KUUPÄEV]',
  PROVIDER = '[TEENUSEPAKKUJA_NIMI]',
  REG_CODE = '[REGISTRIKOOD]',
  KMKR_NUMBER = '[KMKR_NUMBER]',
  ADDRESS = '[AADRESS]',
  TELEPHONE = '[TELEFON]',
  EMAIL = '[EMAIL]',
}

export enum PERMISSIONS {
  READ_CALENDAR = 'READ_CALENDAR',
  READ_CLIENTS = 'READ_CLIENTS',
  UPDATE_CLIENTS = 'UPDATE_CLIENTS',
  READ_TASKS = 'READ_TASKS',
  UPDATE_TASKS = 'UPDATE_TASKS',
  READ_SERVICES = 'READ_SERVICES',
  UPDATE_SERVICE_CONTENT = 'UPDATE_SERVICE_CONTENT',
  UPDATE_SERVICE_POLICY = 'UPDATE_SERVICE_POLICY',
  UPDATE_SERVICE_IS_PUBLISHED = 'UPDATE_SERVICE_IS_PUBLISHED',
  READ_RESOURCES = 'READ_RESOURCES',
  UPDATE_RESOURCES = 'UPDATE_RESOURCES',
  READ_REPORTS = 'READ_REPORTS',
  //UPDATE_REPORTS = 'UPDATE_REPORTS',
  READ_COMPANY_SETTINGS = 'READ_COMPANY_SETTINGS',
  UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS',
  UPDATE_COMPANY_POLICY = 'UPDATE_COMPANY_POLICY',
  UPDATE_COMPANY_USERS = 'UPDATE_COMPANY_USERS',
  UPDATE_COMPANY_WORKFLOW_HELPERS = 'UPDATE_COMPANY_WORKFLOW_HELPERS',
  READ_TICKETS = 'READ_TICKETS',
  UPDATE_TICKETS = 'UPDATE_TICKETS',
  ADD_COMPANY = 'ADD_COMPANY',
}

export enum COUNTRY_ID {
  ESTONIA = 72,
}

export enum COMPANY_NOTIFICATIONS {
  ORDER_PAID = 'order_paid',
  RESERVATION_CANCELLED = 'reservation_cancelled',
  EVENT_CANCELLED = 'event_cancelled',
  EVENT_CANCELLATION_DELAYED = 'event_cancellation_delayed',
  EVENT_CANCELLATION_PASSED = 'event_cancellation_passed',
  PARTNERSHIP_INVITE_RESPONSE = 'partnership_invite_response',
  PARTNERSHIP_STATUS_CHANGE = 'partnership_status_change',
  PARTNERSHIP_PAYOUT_PERCENTAGE_CHANGE = 'partnership_payout_percentage_change',
}
