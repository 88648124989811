import { PERMISSIONS } from '@/enums/settings'
import { UserInfo } from '@/models/auth.model'
import { Company, UserCompany } from '@/models/company.model'
import { SettingsProfile } from '@/models/settings.model'
import queryClient from '@/queries/queryClient'
import router from '@/router'
import { ROUTE_PATHS } from '@/router/paths'
import { authenticateUser, getUser, logoutUser } from '@/utils/auth'
import { deepMergeExistingKeys } from '@/utils/merge'
import { defineStore } from 'pinia'

interface AuthState {
  token: string
  user: UserInfo | null
  userCompanies: UserCompany[] | null
  selectedCompanyId: number | null
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: localStorage.getItem('tour-token') || '',
    user: null,
    userCompanies: null,
    selectedCompanyId: Number(localStorage.getItem('company-id')) || null,
  }),

  getters: {
    isAuthenticated(state) {
      return !!state.token
    },

    selectedCompany(state): UserCompany | undefined {
      if (state.selectedCompanyId && state.userCompanies) {
        return state.userCompanies?.find(
          (userCompany) => state.selectedCompanyId === userCompany.company.id,
        )
      }

      return undefined
    },

    rolePermissions(state): PERMISSIONS[] | undefined {
      if (state.selectedCompanyId && state.userCompanies) {
        const selectedUserCompany: UserCompany | undefined =
          state.userCompanies?.find(
            (userCompany) => state.selectedCompanyId === userCompany.company.id,
          )
        return selectedUserCompany?.role.permissions
      }
      return undefined
    },

    canViewPropertyChannel(state): boolean {
      // TODO: TOUR-118: Replace with new and more relevant permission
      return (
        this?.rolePermissions?.includes(PERMISSIONS.UPDATE_COMPANY_POLICY) ||
        false
      )
    },

    canCreateOrModifyPropertyChannel(state): boolean {
      // TODO: TOUR-118: Replace with new and more relevant permission
      return (
        this?.rolePermissions?.includes(PERMISSIONS.UPDATE_COMPANY_POLICY) ||
        false
      )
    },

    canReadTickets(state): boolean {
      return this?.rolePermissions?.includes(PERMISSIONS.READ_TICKETS) || false
    },

    canUpdateTickets(state): boolean {
      return (
        this?.rolePermissions?.includes(PERMISSIONS.UPDATE_TICKETS) || false
      )
    },

    userId(state): string | null {
      return state.user ? state.user.id : null
    },

    isAdminUser(state) {
      return !!state.token && !!state.user?.is_admin
    },
  },

  actions: {
    async login(email: string, password: string) {
      try {
        const response = await authenticateUser(email, password)
        if (!response) {
          return
        }

        this.token = response.token

        this.user = { ...response.user, user_companies: null }
        this.userCompanies = response.user.user_companies

        localStorage.setItem('tour-token', response.token)
      } catch (e) {
        this.$reset()
        throw e
      }
    },

    selectCompany(id: number) {
      if (
        this.userCompanies?.some((userCompany) => userCompany.company.id === id)
      ) {
        this.selectedCompanyId = id

        localStorage.setItem('company-id', String(id))
        router.push({
          name: ROUTE_PATHS.OVERVIEW,
          query: {
            next: ROUTE_PATHS.OVERVIEW,
          },
        })
      }
    },

    addCompany(company: UserCompany) {
      this.userCompanies = [...(this.userCompanies || []), company]
    },

    updateSelectedCompanyData(form: SettingsProfile) {
      this.userCompanies =
        this.userCompanies?.map((item: UserCompany) => {
          if (item.company.id === this.selectedCompanyId) {
            const company = deepMergeExistingKeys(item.company, form)
            item.company = company as Company
            return item
          } else {
            return item
          }
        }) || null
    },
    async getUser() {
      try {
        const response = await getUser()
        if (!response) {
          this.$reset()
          return
        }

        this.user = { ...response, user_companies: null }
        this.userCompanies = response.user_companies
      } catch (e) {
        this.$reset()
        throw e
      }
    },

    async logoutLocallyAndRedirectToLogin(next?: string) {
      localStorage.removeItem('tour-token')
      this.$reset()

      // Clear all connected caches
      queryClient.clear()

      console.log('next: ', next)
      router.push({
        name: ROUTE_PATHS.LOGIN,
        query: {
          next: next === ROUTE_PATHS.LOGIN ? undefined : next,
        },
      })
    },

    async logout(next?: string) {
      logoutUser()
      this.logoutLocallyAndRedirectToLogin(next)
    },
  },
})
