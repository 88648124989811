import { ROUTE_PATHS } from './paths'

export const BASE_ACCESS_ROUTE_PATH = [
  ROUTE_PATHS.LOGIN,
  ROUTE_PATHS.REGISTER,
  ROUTE_PATHS.CHOOSE_COMPANY,
  ROUTE_PATHS.RESET_PASSWORD,
  ROUTE_PATHS.SET_NEW_PASSWORD,
  ROUTE_PATHS.INVITED_USER,
]

export const TICKET_INSPECTOR_ACCESS_ROUTE_PATH = [
  ...BASE_ACCESS_ROUTE_PATH,
  ROUTE_PATHS.BOOKING,
  ROUTE_PATHS.TICKET_VALIDATION,
]
